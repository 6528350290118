// Generated by Framer (c60b0a0)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as localizedValues from "./Bz4ORwWci-0.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["j9IL84VBr", "iVoHJ0dXT"];

const serializationHash = "framer-G9D1R"

const variantClassNames = {iVoHJ0dXT: "framer-v-180q6dx", j9IL84VBr: "framer-v-1tkoo4i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {NoBullet: "iVoHJ0dXT", WithBullet: "j9IL84VBr"}

const getProps = ({bullet1, bulletPoint, height, iconColor, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "j9IL84VBr", wyVtrTxBC: bullet1 ?? props.wyVtrTxBC ?? "Bullet1", YavVk6wo2: bulletPoint ?? props.YavVk6wo2 ?? true, Zxu0mypa_: iconColor ?? props.Zxu0mypa_ ?? "rgb(34, 34, 34)"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;iconColor?: string;bullet1?: string;bulletPoint?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Zxu0mypa_, wyVtrTxBC, YavVk6wo2, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "j9IL84VBr", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "iVoHJ0dXT") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}>{YavVk6wo2 && (<Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1tkoo4i", className, classNames)} data-framer-name={"WithBullet"} layoutDependency={layoutDependency} layoutId={"j9IL84VBr"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({iVoHJ0dXT: {"data-framer-name": "NoBullet"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-6jnxn0"} layoutDependency={layoutDependency} layoutId={"AJUikMAmf"}><ComponentViewportProvider ><motion.div className={"framer-zpnftq-container"} layoutDependency={layoutDependency} layoutId={"fyZlf5jCq-container"}><Phosphor color={Zxu0mypa_} height={"100%"} iconSearch={"circle"} iconSelection={"House"} id={"fyZlf5jCq"} layoutId={"fyZlf5jCq"} mirrored={false} selectByList={false} style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div>)}<RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "R0Y7T3BlbiBTYW5zLXJlZ3VsYXI=", "--framer-font-family": "\"Open Sans\", \"Open Sans Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-1of0zx5, rgb(51, 51, 51))"}}>Bullet1</motion.h2></React.Fragment>} className={"framer-14ytp48"} data-framer-name={"Bullets Description"} fonts={["GF;Open Sans-regular"]} layoutDependency={layoutDependency} layoutId={"FKGLDQzx4"} style={{"--extracted-1of0zx5": "rgb(51, 51, 51)"}} text={wyVtrTxBC} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>)}</Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-G9D1R.framer-1l3u18v, .framer-G9D1R .framer-1l3u18v { display: block; }", ".framer-G9D1R.framer-1tkoo4i { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; overflow: visible; padding: 3px 0px 3px 0px; position: relative; width: 625px; }", ".framer-G9D1R .framer-6jnxn0 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 2px 0px 0px 0px; position: relative; width: min-content; }", ".framer-G9D1R .framer-zpnftq-container { flex: none; height: 8px; position: relative; width: 8px; }", ".framer-G9D1R .framer-14ytp48 { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-G9D1R.framer-1tkoo4i, .framer-G9D1R .framer-6jnxn0 { gap: 0px; } .framer-G9D1R.framer-1tkoo4i > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-G9D1R.framer-1tkoo4i > :first-child, .framer-G9D1R .framer-6jnxn0 > :first-child { margin-left: 0px; } .framer-G9D1R.framer-1tkoo4i > :last-child, .framer-G9D1R .framer-6jnxn0 > :last-child { margin-right: 0px; } .framer-G9D1R .framer-6jnxn0 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 25
 * @framerIntrinsicWidth 625
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"iVoHJ0dXT":{"layout":["fixed","auto"]}}}
 * @framerVariables {"Zxu0mypa_":"iconColor","wyVtrTxBC":"bullet1","YavVk6wo2":"bulletPoint"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerBz4ORwWci: React.ComponentType<Props> = withCSS(Component, css, "framer-G9D1R") as typeof Component;
export default FramerBz4ORwWci;

FramerBz4ORwWci.displayName = "NewsBullet";

FramerBz4ORwWci.defaultProps = {height: 25, width: 625};

addPropertyControls(FramerBz4ORwWci, {variant: {options: ["j9IL84VBr", "iVoHJ0dXT"], optionTitles: ["WithBullet", "NoBullet"], title: "Variant", type: ControlType.Enum}, Zxu0mypa_: {defaultValue: "rgb(34, 34, 34)", title: "IconColor", type: ControlType.Color}, wyVtrTxBC: {defaultValue: "Bullet1", displayTextArea: false, placeholder: "", title: "Bullet1", type: ControlType.String}, YavVk6wo2: {defaultValue: true, title: "BulletPoint?", type: ControlType.Boolean}} as any)

addFonts(FramerBz4ORwWci, [{explicitInter: true, fonts: [{family: "Open Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4iY1M2xLER.woff2", weight: "400"}]}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})